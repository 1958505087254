export interface IEmploymentForm {
  id: number
  name: string
}

export class EmploymentForm implements IEmploymentForm {
  public id = 0
  public name = ''

  constructor(data?: IEmploymentForm) {
    Object.assign(this, data)
  }
}

export const employmentFormNone = new EmploymentForm({
  id: 0,
  name: 'NONE',
})
