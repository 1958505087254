import { msalClient, coreClient } from '@/libs'
import { User, IUser } from '@/types'
import { AuthenticationResult } from '@azure/msal-browser'
import axios, { AxiosResponse } from 'axios'
import { Buffer } from 'buffer'

export class UserService {
  public static async getUser(): Promise<User> {
    const { data } = await coreClient.get<IUser>('/api/user')
    return new User(data)
  }

  public static async getUserAvatar(): Promise<string> {
    let authRes: AuthenticationResult

    try {
      const scopes: string[] = ['user.read']
      authRes = await msalClient.aquireTokenForScopes(scopes)
    } catch (err) {
      window.console.error('Error acquire token silent when fetching user avatar:', err)
      throw err
    }

    try {
      const httpClient = axios.create({
        headers: {
          Authorization: `${authRes.tokenType} ${authRes.accessToken}`,
        },
      })

      const resp: AxiosResponse = await httpClient.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        responseType: 'arraybuffer',
      })

      const imgB64 = Buffer.from(resp.data, 'binary').toString('base64')
      return imgB64
    } catch (err) {
      window.console.error('Error fetching user avatar:', err)
      throw err
    }
  }

  public static async signIn(): Promise<boolean> {
    return msalClient.signIn()
  }

  public static async signOut(): Promise<void> {
    return msalClient.signOut()
  }
}
