import {
  EmployeeRelativeRelation,
  employeeRelativeRelationNone,
  IEmployeeRelativeRelation,
} from './employeeRelativeRelation'
import { EmploymentForm, employmentFormNone, IEmploymentForm } from './employmentForm'
import { Campaign, campaignNone, ICampaign } from './campaign'
import { IUser, User } from './user'

export interface INewEmployee {
  id: number

  firstName: string
  lastName: string

  personalIdentityNumber: string

  address: string
  address2: string
  postalCode: string
  city: string

  phoneHome: string
  phoneMobile: string

  relativeName: string
  relativePhone: string
  relativeRelation: IEmployeeRelativeRelation

  bankClearing: string
  bankAccount: string

  info: string

  employmentDate: Date | undefined
  employmentForm: IEmploymentForm

  campaign: ICampaign

  createdBy: IUser | undefined
  createdAt: Date | undefined

  password?: string
  companyEmailAddress?: string
  campaignEmailAddress?: string
  employeeNumber: number | null

  accountsCreatedBy?: IUser | undefined
  accountsCreatedAt?: Date
}

export class NewEmployee implements INewEmployee {
  id = 0

  firstName = ''
  lastName = ''
  personalIdentityNumber = ''

  address = ''
  address2 = ''
  postalCode = ''
  city = ''

  phoneHome = ''
  phoneMobile = ''

  relativeName = ''
  relativePhone = ''
  relativeRelation = employeeRelativeRelationNone

  bankClearing = ''
  bankAccount = ''

  employmentDate: Date | undefined = undefined
  employmentForm = employmentFormNone

  info = ''

  campaign = campaignNone

  createdAt: Date | undefined = undefined
  createdBy: IUser | undefined = undefined

  password?: string
  companyEmailAddress?: string
  campaignEmailAddress?: string
  employeeNumber: number | null = null

  accountsCreatedBy?: IUser | undefined
  accountsCreatedAt?: Date

  constructor(data?: INewEmployee) {
    Object.assign(this, data)

    if (data?.campaign) {
      this.campaign = new Campaign(data.campaign)
    }

    if (data?.employmentForm) {
      this.employmentForm = new EmploymentForm(data.employmentForm)
    }

    if (data?.relativeRelation) {
      this.relativeRelation = new EmployeeRelativeRelation(data.relativeRelation)
    }

    if (data?.employmentDate) {
      this.employmentDate = new Date(data.employmentDate)
    }

    if (data?.createdBy) {
      this.createdBy = new User(data.createdBy)
    }

    if (data?.createdAt) {
      this.createdAt = new Date(data.createdAt)
    }

    if (data?.accountsCreatedBy) {
      this.accountsCreatedBy = new User(data.accountsCreatedBy)
    }

    if (data?.accountsCreatedAt) {
      this.accountsCreatedAt = new Date(data.accountsCreatedAt)
    }
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`.trim()
  }
}
