import { coreClient } from '@/libs'
import { Campaign, ICampaign } from '@/types'

export class CampaignService {
  public static async fetchCampaigns(
    onlyActive = true,
    includeDomains = false,
    includeManager = false
  ): Promise<Campaign[]> {
    let url = '/api/campaigns'

    const q = []

    if (onlyActive) {
      q.push('onlyActive=true')
    }

    if (includeDomains) {
      q.push('includeDomains=true')
    }

    if (includeManager) {
      q.push('includeManagers=true')
    }

    if (q.length > 0) {
      url += `?${q.join('&')}`
    }

    const { data } = await coreClient.get<ICampaign[]>(url)
    return data.map((d) => new Campaign(d))
  }
}
