
import { Vue } from 'vue-class-component'
import { Options } from 'vue-class-component'
import ProfileComponent from '@/components/ProfileComponent.vue'
import { SessionModule } from '@/store/modules/session'
import { User } from '@/types'

@Options({
  name: 'NavBarComponent',
  components: {
    ProfileComponent,
  },
})
export default class NavBarComponent extends Vue {
  get isSignedIn(): boolean {
    return SessionModule.isSignedIn
  }

  get user(): User | null {
    return SessionModule.user
  }
}
