import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      requiresAuth: false,
    },
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requireAuth: false,
    },
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requireAuth: false,
    },
    component: () => import('@/views/Login.vue'),
  },

  {
    path: '/entites/employees',
    name: 'Employees',
    meta: {
      requireAuth: false,
    },
    component: () => import('@/views/entities/employees/Employees.vue'),
  },
  {
    path: '/entites/employees/:employeeNumber',
    name: 'ShowEmployee',
    meta: {
      requireAuth: false,
    },
    component: () => import('@/views/entities/employees/Employee.vue'),
  },

  {
    path: '/entites/newEmployees',
    component: () => import('@/views/entities/newEmployees/NewEmployees.vue'),
    children: [
      {
        path: '',
        redirect: '/entites/newEmployees/create',
      },
      {
        path: '/entites/newEmployees/create',
        component: () => import('@/views/entities/newEmployees/NewEmployeesCreate.vue'),
      },
      {
        name: 'EditNewEmployees',
        path: '/entites/newEmployees/edit/:newEmployeeId?',
        component: () => import('@/views/entities/newEmployees/NewEmployeesEdit.vue'),
      },
      {
        name: 'AddNewEmployeeAccounts',
        path: '/entites/newEmployees/addAccounts/:newEmployeeId?',
        component: () => import('@/views/entities/newEmployees/NewEmployeesAddAccounts.vue'),
      },
    ],
  },

  {
    path: '/entites/campaigns',
    component: () => import('@/views/entities/Campaigns.vue'),
  },
  {
    path: '/entites/articles',
    component: () => import('@/views/entities/Articles.vue'),
  },

  {
    path: '/status',
    name: 'Status',
    component: () => import('@/views/system/Status.vue'),
  },
  {
    path: '/system/tasks',
    component: () => import('@/views/system/Tasks.vue'),
  },
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
