
import { Options, Vue } from 'vue-class-component'
import { SessionModule } from '@/store/modules/session'
import { User } from '@/types'

@Options({
  name: 'ProfileComponent',
})
export default class ProfileComponent extends Vue {
  get user(): User | null {
    return SessionModule.user
  }

  get updatingUser(): boolean {
    return SessionModule.updatingUser
  }

  get profilePhoto(): string {
    const img = SessionModule.profilePhoto
    if (img === null) {
      return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
    }

    return `data:image/jpeg;base64,${img}`
  }

  async signOut(): Promise<void> {
    await SessionModule.signOut()
    this.$router.push({ name: 'Home' })
  }
}
