
import { Options, Vue } from 'vue-class-component'
import NavBarComponent from '@/components/NavBarComponent.vue'

@Options({
  name: 'MainApp',
  components: {
    NavBarComponent,
  },
})
export default class MainApp extends Vue {}
