import { IAppRole } from '.'

export interface IUser {
  isAuthenticated: boolean
  uuid: string
  email: string
  givenName: string
  surname: string
  appRoles: IAppRole[]

  fullName: string
}

export class User implements IUser {
  isAuthenticated = false
  uuid = ''
  email = ''
  givenName = ''
  surname = ''
  appRoles: IAppRole[] = []

  constructor(data?: IUser) {
    Object.assign(this, data)
  }

  public get fullName(): string {
    let name = ''

    if (this.givenName) {
      name = this.givenName
      if (this.surname) {
        name += ' '
      }
    }
    if (this.surname) {
      name += this.surname
    }

    return name
  }

  public get isAdmin(): boolean {
    return this.hasRole('Systemadministratör')
  }

  private hasRole(roleName: string): boolean {
    for (const role of this.appRoles) {
      if (role.name === roleName) {
        return true
      }
    }

    return false
  }
}
