import {
  PublicClientApplication,
  Configuration,
  BrowserCacheLocation,
  PopupRequest,
  AuthenticationResult,
  EndSessionPopupRequest,
} from '@azure/msal-browser'
import { SessionModule } from '@/store/modules/session'

export interface IMsalClient {
  signIn(): Promise<boolean>
  signInRedirect(redirectUri?: string): Promise<void>
  signOut(): Promise<void>
  handleRedirectPromise(): Promise<boolean>
}

class MsalClient implements IMsalClient {
  private app: PublicClientApplication

  private readonly scopeCore = `api://${process.env.VUE_APP_AAD_CLIENT_ID}/core.access`

  static msalOptions: Configuration = {
    auth: {
      clientId: process.env.VUE_APP_AAD_CLIENT_ID as string,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AAD_TENANT_ID}`,
      redirectUri: `${process.env.VUE_APP_BASE_URL}/login`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  }

  constructor() {
    this.app = new PublicClientApplication(MsalClient.msalOptions)
  }

  public async init() {
    const popupRequest = this.popupRequest()

    try {
      SessionModule.setSigningIn(true)

      const accounts = this.app.getAllAccounts()
      if (accounts.length === 0) {
        SessionModule.clear()
        return
      }

      const account = accounts[0]

      this.app.setActiveAccount(account)

      const result: AuthenticationResult = await this.app.acquireTokenSilent(popupRequest)

      await this.responseCallback(result)
    } catch (err: unknown) {
      await this.app.acquireTokenRedirect(popupRequest)
    } finally {
      SessionModule.setSigningIn(false)
    }
  }

  public async signIn(): Promise<boolean> {
    try {
      SessionModule.setSigningIn(true)

      const popupRequest: PopupRequest = {
        scopes: [this.scopeCore],
      }

      const result: AuthenticationResult = await this.app.loginPopup(popupRequest)
      await this.responseCallback(result)
      return true
    } catch (err: unknown) {
      window.console.error(`Error during authentication: ${err}`)
      SessionModule.clear()
      return false
    } finally {
      SessionModule.setSigningIn(false)
    }
  }

  public async signInRedirect(redirectPath?: string): Promise<void> {
    if (redirectPath?.charAt(0) == '/') {
      redirectPath = redirectPath.substring(1)
    }

    const redirectUri = `${process.env.VUE_APP_BASE_URL}/${redirectPath}`

    try {
      const popupRequest: PopupRequest = {
        scopes: [this.scopeCore],
        redirectUri,
      }

      await this.app.loginRedirect(popupRequest)
    } catch (err: unknown) {
      window.console.error(`Error during authentication: ${err}`)
      SessionModule.clear()
    }
  }

  public async signOut(): Promise<void> {
    try {
      const account = this.app.getActiveAccount()

      const request: EndSessionPopupRequest = {
        account,
      }

      await this.app.logoutPopup(request)

      SessionModule.clear()
    } finally {
      // Do nothing if user cancel logout process
    }
  }

  public async aquireTokenForScopes(scopes: string[]): Promise<AuthenticationResult> {
    return this.app.acquireTokenSilent({ scopes })
  }

  public async handleRedirectPromise(): Promise<boolean> {
    const result = await this.app.handleRedirectPromise()
    if (result !== null) {
      await this.responseCallback(result)
      return true
    } else {
      return false
    }
  }

  private popupRequest(redirectUri?: string): PopupRequest {
    const popupRequest: PopupRequest = {
      scopes: [this.scopeCore],
      redirectUri,
    }

    return popupRequest
  }

  private async responseCallback(result: AuthenticationResult) {
    SessionModule.setAccount(result.account)
    this.app.setActiveAccount(result.account)

    const token = { token: result.accessToken, tokenType: result.tokenType }
    SessionModule.setAccessToken(token)

    await Promise.all([SessionModule.updateProfilePhoto(), SessionModule.updateUserInfo()])
  }

  // private requiresInteraction(errorCode: string) {
  //   if (!errorCode || !errorCode.length) {
  //     return false
  //   }

  //   return errorCode === 'consent_required' || errorCode === 'interaction_required' || errorCode === 'login_required'
  // }
}

export const msalClient = new MsalClient()
