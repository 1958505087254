export interface ICampaign {
  id: number
  name: string
  abbreviation: string
  active: boolean

  emailDomain?: string
  managerDn?: string
}

export class Campaign implements ICampaign {
  id = 0
  name = ''
  abbreviation = ''
  active = false

  emailDomain = undefined
  managerDn = undefined

  constructor(data: ICampaign) {
    Object.assign(this, data)
  }

  get isAdministration(): boolean {
    return this.id === 10
  }
}

export const campaignNone: Campaign = new Campaign({
  id: 0,
  name: 'NONE',
  abbreviation: 'NO',
  active: false,
})
