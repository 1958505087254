import { ComponentPublicInstance, createApp } from 'vue'

import { store } from '@/store'
import { router, coreHttpClient, fontAwesome, msalClient } from '@/libs'
import MessagesComponent from '@/components/Messages.vue'

import './style/style.scss'

import App from '@/App.vue'

msalClient.init().then(() => {
  const app = createApp(App)

  const __DEBUG__: boolean = process.env.NODE_ENV !== 'PRODUCTION'

  if (__DEBUG__) {
    app.config.errorHandler = (err: unknown, instance: ComponentPublicInstance | null, info: string) => {
      console.error('*** ERROR ***')
      console.error('  err --> ', err)
      console.error('  info --> ', info)
      console.error('  vm --> ', instance)
    }

    app.config.warnHandler = (msg: string, instance: ComponentPublicInstance | null, trace: string) => {
      console.log('*** WARNING ***')
      console.log('  msg --> ', msg)
      console.log('  trace --> ', trace)
      console.log('  vm --> ', instance)
    }
  }

  app
    .use(store)
    .use(router)
    .use(coreHttpClient, router)
    .use(fontAwesome)
    .component('messages', MessagesComponent)
    .mount('#app')
})
