export class PasswordService {
  static generatePassword(): string {
    const adjectives = ['Vacker', 'Fin', 'Mjuk', 'Lycklig', 'Gullig', 'Snäll', 'Modig', 'Varm']

    const nouns = [
      'Sommar',
      'Sol',
      'Badboll',
      'Skridsko',
      'Snö',
      'Sten',
      'Jord',
      'Himmel',
      'Blomma',
      'Boll',
      'Banan',
      'Choklad',
    ]

    const r1 = Math.floor(Math.random() * adjectives.length)
    const r2 = Math.floor(Math.random() * nouns.length)
    const r3 = Math.floor(Math.random() * 89) + 10

    return adjectives[r1] + nouns[r2] + r3
  }
}
