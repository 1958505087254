import { Campaign, campaignNone, ICampaign } from './campaign'

export interface IArticle {
  id: number
  name: string
  productID: string
  campaign: ICampaign
}

export class Article implements IArticle {
  id = 0
  name = ''
  productID = ''
  campaign = campaignNone

  constructor(data?: IArticle) {
    Object.assign(this, data)

    if (data?.campaign) {
      this.campaign = new Campaign(data.campaign)
    }
  }
}
