import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { SessionModule } from '@/store/modules/session'
import { Router } from 'vue-router'
import { App } from 'vue'

export const coreClient: AxiosInstance = axios.create({
  baseURL: '/',
  timeout: 300000,
  headers: { 'X-App': 'Core.Services.AdminUI' },
})

let _router: Router | null = null
const __DEBUG__: boolean = process.env.NODE_ENV !== 'PRODUCTION'

export function coreHttpClient(_: App, router: Router): void {
  _router = router
}

coreClient.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    try {
      const t = SessionModule.accessToken
      if (t !== null) {
        if (config.headers) {
          config.headers.Authorization = `${t.tokenType} ${t.token}`
        }
        return config
      }
    } catch (err: unknown) {
      console.log('*** Unable to aquire token ***')
      console.log(err)
      if (config.headers) {
        delete config.headers.Authorization
      }
    }

    return config
  },
  (error: unknown) => {
    Promise.reject(error)
  }
)

coreClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (err: unknown) => {
    if (axios.isAxiosError(err)) {
      const axiosErr = err as AxiosError
      if (axiosErr.response?.status === 401) {
        if (_router) {
          _router.push({
            name: 'Login',
            query: {
              redirect: encodeURIComponent(_router.currentRoute.value.fullPath),
            },
          })
          return
        } else {
          console.error('[CoreHttpClient] Got a 401 and _router is null or undefined... Cannot do anything. Give up!')
          window.alert('Ett litet fel har uppstått... Du måste ladda om sidan. :)')
          return
        }
      }

      if (__DEBUG__) {
        console.error('*** Axios network request error ***')
        console.error(err)
      }

      throw axiosErr
    } else {
      console.error('*** Unknown network request error ***')
      console.error(err)
      throw err
    }
  }
)
