import { createStore, createLogger } from 'vuex'
import { ISessionState } from './modules/session'

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

export interface IRootState {
  session: ISessionState
}

export const store = createStore<IRootState>({
  plugins,
})
