import { coreClient } from '@/libs'
import {
  Campaign,
  EmailAvailability,
  Employee,
  EmployeeCampaignAssignment,
  EmployeeMailAddress,
  EmployeeRelativeRelation,
  EmploymentForm,
  IEmailAvailability,
  IEmployee,
  IEmployeeRelativeRelation,
  IEmploymentForm,
  INewEmployee,
  NewEmployee,
} from '@/types'

export interface IAddUpdateNewEmployeePayload {
  firstName: string
  lastName: string

  personalIdentityNumber: string

  address: string
  address2: string
  postalCode: string
  city: string

  phoneHome: string
  phoneMobile: string

  relativeName: string
  relativePhone: string
  relativeRelationId?: number

  bankClearing: string
  bankAccount: string

  info: string

  employmentDate?: string
  employmentFormId?: number

  campaignId?: number
}

export interface ICreateEmployeeFromNewEmployeePayload {
  newEmployeeId: number
  password: string
  companyEmailAddress: string
  campaignEmailAddress?: string
}

export class EmployeeService {
  // #region Helpers
  public static async fetchEmploymentForms(): Promise<EmploymentForm[]> {
    const { data } = await coreClient.get<IEmploymentForm[]>(`/api/employees/employmentForms`)
    return data.map((d) => new EmploymentForm(d))
  }

  public static async fetchEmployeeRelativeRelations(): Promise<EmployeeRelativeRelation[]> {
    const { data } = await coreClient.get<IEmployeeRelativeRelation[]>(`/api/employees/employeeRelativeRelations`)
    return data.map((d) => new EmployeeRelativeRelation(d))
  }

  public static async isEmailAddressAvailable(emailAddress: string): Promise<EmailAvailability> {
    const { data } = await coreClient.post<IEmailAvailability>(`/api/employees/isEmailAvailable`, {
      emailAddress,
    })
    return new EmailAvailability(data)
  }
  // #endregion

  // #region New Employee
  public static async fetchNewEmployees(
    withoutAccount = false,
    includeRecentlyCreatedAccount = false
  ): Promise<NewEmployee[]> {
    let url = '/api/newEmployees'

    const q = []

    if (withoutAccount) {
      q.push('withoutAccount=true')
    }

    if (includeRecentlyCreatedAccount) {
      q.push('includeRecentlyCreatedAccount=true')
    }

    if (q.length > 0) {
      url += `?${q.join('&')}`
    }

    const { data } = await coreClient.get<INewEmployee[]>(url)
    const newEmployees = data.map((data) => new NewEmployee(data))
    return newEmployees.sort((a: NewEmployee, b: NewEmployee) => {
      return a.firstName.localeCompare(b.firstName, 'sv-SE')
    })
  }

  public static async createNewEmployee(payload: IAddUpdateNewEmployeePayload): Promise<NewEmployee> {
    const { data } = await coreClient.post<INewEmployee>(`/api/newEmployees`, payload)
    return new NewEmployee(data)
  }

  public static async updateNewEmployee(
    newEmployeeId: number,
    payload: IAddUpdateNewEmployeePayload
  ): Promise<NewEmployee> {
    const { data } = await coreClient.patch<INewEmployee>(`/api/newEmployees/${newEmployeeId}`, payload)
    return new NewEmployee(data)
  }

  public static async deleteNewEmployee(newEmployee: NewEmployee): Promise<void> {
    await coreClient.delete(`/api/newEmployees/${newEmployee.id}`)
  }

  public static async createEmployeeFromNewEmployee(payload: ICreateEmployeeFromNewEmployeePayload): Promise<Employee> {
    const { data } = await coreClient.post<IEmployee>(`/api/newEmployees/create`, payload)
    return new Employee(data)
  }

  public static async notifyEmployeeCreated(newEmployee: NewEmployee, recipient: string): Promise<void> {
    await coreClient.post(`/api/newEmployees/${newEmployee.id}/notifyCreated`, { recipient })
  }
  // #endregion

  // #region Employees
  public static async fetchEmployees(): Promise<Employee[]> {
    const { data } = await coreClient.get<IEmployee[]>(`/api/employees`)
    const employees = data.map((d) => new Employee(d))
    return employees.sort((a: Employee, b: Employee) => {
      if (a.firstName && b.firstName) {
        return a.firstName.localeCompare(b.firstName, 'sv-SE')
      } else if (a.firstName) {
        return 1
      } else if (b.firstName) {
        return -1
      }

      return 0
    })
  }

  public static async fetchEmployee(employeeNumber: number): Promise<Employee | null> {
    const { data, status } = await coreClient.get<IEmployee>(`/api/employees/${employeeNumber}`)

    if (status != 200) {
      return null
    }

    return new Employee(data)
  }

  public static async updateEmployee(employee: Employee): Promise<Employee> {
    const { data } = await coreClient.post<IEmployee>(`/api/employees/${employee.employeeNumber}`, employee)
    return new Employee(data)
  }

  public static setPhoneExtension(e: Employee, extension: number): Promise<void> {
    return coreClient.put(`/api/employees/${e.employeeNumber}/phoneExtension`, { extension })
  }

  public static clearPhoneExtension(e: Employee): Promise<void> {
    return coreClient.delete(`/api/employees/${e.employeeNumber}/phoneExtension`)
  }

  public static async addCampaignAssignmen(e: Employee, c: Campaign): Promise<Employee> {
    const { data } = await coreClient.post(`/api/employees/${e.employeeNumber}/campaignAssignments`, {
      campaignId: c.id,
    })
    return new Employee(data)
  }

  public static async removeCampaignAssignmen(e: Employee, ca: EmployeeCampaignAssignment): Promise<Employee> {
    const { data } = await coreClient.delete(`/api/employees/${e.employeeNumber}/campaignAssignments/${ca.id}`)
    return new Employee(data)
  }

  public static async addEmailAddress(e: Employee, email: string): Promise<Employee> {
    const { data } = await coreClient.post(`/api/employees/${e.employeeNumber}/mailAddresses`, {
      emailAddress: email,
    })
    return new Employee(data)
  }

  public static async removeMailAddress(e: Employee, email: EmployeeMailAddress): Promise<Employee> {
    const { data } = await coreClient.delete(`/api/employees/${e.employeeNumber}/mailAddresses/${email.id}`)
    return new Employee(data)
  }

  public static async setPrimaryMailAddress(e: Employee, email: EmployeeMailAddress): Promise<Employee> {
    const { data } = await coreClient.post(`/api/employees/${e.employeeNumber}/mailAddresses/${email.id}/makePrimary`)
    return new Employee(data)
  }
  // #endregion
}
