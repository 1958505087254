export interface IEmailAvailability {
  emailAddress: string
  isAvailable: boolean
}

export class EmailAvailability implements IEmailAvailability {
  emailAddress = ''
  isAvailable = false

  constructor(data?: IEmailAvailability) {
    Object.assign(this, data)
  }
}
