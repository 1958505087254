export interface IPostalAddress {
  id: number
  addresses: string[]
  postalCode: string
  city: string
  countryCode: string
}

export class PostalAddress implements IPostalAddress {
  id = 0
  addresses = []
  postalCode = ''
  city = ''
  countryCode = ''

  constructor(data?: IPostalAddress) {
    Object.assign(this, data)
  }
}
