import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  href: "#",
  class: "d-block link-dark text-decoration-none dropdown-toggle",
  id: "navbarDropdownUser",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "ms-2"
}
const _hoisted_5 = {
  key: 0,
  class: "dropdown-menu dropdown-menu-end",
  "aria-labelledby": "navbarDropdownUser"
}
const _hoisted_6 = {
  key: 1,
  class: "dropdown-menu dropdown-menu-end",
  "aria-labelledby": "navbarDropdownUser"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.profilePhoto,
        alt: "[Photo]",
        width: "32",
        height: "32",
        class: "rounded-circle"
      }, null, 8, _hoisted_3),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.user.fullName), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.updatingUser)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          _createElementVNode("li", null, [
            _createVNode(_component_fa_icon, { icon: "spinner" })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.user && !_ctx.updatingUser)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              class: "dropdown-item",
              to: "/profile"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Profil")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              class: "dropdown-item",
              href: "#",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args)))
            }, "Logga ut")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}