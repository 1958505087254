import { Campaign, campaignNone, ICampaign } from './campaign'
import {
  EmployeeRelativeRelation,
  employeeRelativeRelationNone,
  IEmployeeRelativeRelation,
} from './employeeRelativeRelation'
import { IPostalAddress, PostalAddress } from './postalAddress'
import { IUser, User } from './user'

export interface IEmployee {
  employeeNumber: number

  firstName?: string | null
  lastName?: string | null

  personalIdentityNumber?: string | null

  postalAddress?: IPostalAddress | null

  phoneNumbers?: IEmployeePhoneNumber[]
  phoneExtensions?: IEmployeePhoneExtension[]
  mailAddresses?: IEmployeeMailAddress[]
  campaignAssignments?: IEmployeeCampaignAssignment[]

  bankAccount?: IEmployeeBankAccount | null
  relative?: IEmployeeRelative | null

  createdBy?: IUser | undefined
  createdAt?: Date | undefined
}

export interface IEmployeePhoneNumber {
  id: number
  phoneNumber: string
}

export interface IEmployeePhoneExtension {
  id: number
  extension: number
  fromDate: Date | null
  toDate: Date | null
}

export interface IEmployeeMailAddress {
  id: number
  mailAddress: string
  isPrimary: boolean
  fromDate: Date | null
  toDate: Date | null
}

export interface IEmployeeCampaignAssignment {
  id: number
  campaign: ICampaign | null
  fromDate: Date | null
  toDate: Date | null
}

export interface IEmployeeBankAccount {
  id: number
  clearingNumber?: string
  accountNumber?: string
}

export interface IEmployeeRelative {
  id: number
  name: string
  phoneNumber?: string | null
  info?: string | null
  relation: IEmployeeRelativeRelation
}

export class Employee implements IEmployee {
  employeeNumber = 0

  firstName: string | null = null
  lastName: string | null = null

  personalIdentityNumber: string | null = null

  postalAddress: PostalAddress | null = null

  phoneNumbers: EmployeePhoneNumber[] = []
  phoneExtensions: EmployeePhoneExtension[] = []
  mailAddresses: EmployeeMailAddress[] = []
  campaignAssignments: EmployeeCampaignAssignment[] = []

  bankAccount: IEmployeeBankAccount | null = null
  relative: IEmployeeRelative | null = null

  createdAt: Date | undefined = undefined
  createdBy: IUser | undefined = undefined

  constructor(data?: IEmployee) {
    Object.assign(this, data)

    if (data?.postalAddress) {
      this.postalAddress = new PostalAddress(data.postalAddress)
    }

    if (data?.phoneNumbers) {
      this.phoneNumbers = data.phoneNumbers.map((p) => new EmployeePhoneNumber(p))
    }

    if (data?.phoneExtensions) {
      this.phoneExtensions = data.phoneExtensions.map((p) => new EmployeePhoneExtension(p))
    }

    if (data?.mailAddresses) {
      this.mailAddresses = data.mailAddresses.map((p) => new EmployeeMailAddress(p))
    }

    if (data?.campaignAssignments) {
      this.campaignAssignments = data.campaignAssignments.map((p) => new EmployeeCampaignAssignment(p))
    }

    if (data?.bankAccount) {
      this.bankAccount = new EmployeeBankAccount(data.bankAccount)
    }

    if (data?.relative) {
      this.relative = new EmployeeRelative(data.relative)
    }

    if (data?.createdBy) {
      this.createdBy = new User(data.createdBy)
    }

    if (data?.createdAt) {
      this.createdAt = new Date(data.createdAt)
    }
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`.trim()
  }

  public get currentExtension(): EmployeePhoneExtension | null {
    return this.phoneExtensions.find((ext) => ext.toDate === null) ?? null
  }

  public get phoneExtensionsSorted(): EmployeePhoneExtension[] {
    if (!this.phoneExtensions) {
      return []
    }

    return this.phoneExtensions.sort((a: EmployeePhoneExtension, b: EmployeePhoneExtension) => {
      if (!a.fromDate) {
        return 1
      } else if (!b.fromDate) {
        return -1
      }

      return a.fromDate > b.fromDate ? -1 : 1
    })
  }

  public get activeMailAddresses(): EmployeeMailAddress[] {
    return this.mailAddresses.filter((ma) => ma.toDate === null)
  }

  public get currentCampaigns(): Campaign[] {
    return this.campaignAssignments.map((ca) => ca.campaign ?? []).flat()
  }

  public get activeCampaignAssignments(): EmployeeCampaignAssignment[] {
    return this.campaignAssignments.filter((ca) => ca.toDate === null)
  }
}

export class EmployeePhoneNumber implements IEmployeePhoneNumber {
  id = 0
  phoneNumber = ''

  constructor(data?: IEmployeePhoneNumber) {
    Object.assign(this, data)
  }
}

export class EmployeePhoneExtension implements IEmployeePhoneExtension {
  id = 0
  extension = 0
  fromDate: Date | null = null
  toDate: Date | null = null

  constructor(data?: EmployeePhoneExtension) {
    Object.assign(this, data)

    if (data?.fromDate) {
      this.fromDate = new Date(data.fromDate)
    }

    if (data?.toDate) {
      this.toDate = new Date(data.toDate)
    }
  }
}

export class EmployeeMailAddress implements IEmployeeMailAddress {
  id = 0
  mailAddress = ''
  isPrimary = false
  fromDate: Date | null = null
  toDate: Date | null = null

  constructor(data?: IEmployeeMailAddress) {
    Object.assign(this, data)

    if (data?.fromDate) {
      this.fromDate = new Date(data.fromDate)
    }

    if (data?.toDate) {
      this.toDate = new Date(data.toDate)
    }
  }
}

export class EmployeeCampaignAssignment implements IEmployeeCampaignAssignment {
  id = 0
  campaign: Campaign | null = campaignNone
  fromDate: Date | null = null
  toDate: Date | null = null

  constructor(data?: IEmployeeCampaignAssignment) {
    Object.assign(this, data)

    if (data?.campaign) {
      this.campaign = new Campaign(data.campaign)
    }

    if (data?.fromDate) {
      this.fromDate = new Date(data.fromDate)
    }

    if (data?.toDate) {
      this.toDate = new Date(data.toDate)
    }
  }
}

export class EmployeeBankAccount implements IEmployeeBankAccount {
  id = 0
  clearingNumber?: string
  accountNumber?: string

  constructor(data?: IEmployeeBankAccount) {
    Object.assign(this, data)
  }
}

export class EmployeeRelative implements IEmployeeRelative {
  id = 0
  name = ''
  phoneNumber: string | null = null
  info: string | null = null
  relation: EmployeeRelativeRelation = employeeRelativeRelationNone

  constructor(data?: IEmployeeRelative) {
    Object.assign(this, data)

    if (data?.relation) {
      this.relation = new EmployeeRelativeRelation(data.relation)
    }
  }
}
