export interface IEmployeeRelativeRelation {
  id: number
  name: string
}

export class EmployeeRelativeRelation implements IEmployeeRelativeRelation {
  public id = 0
  public name = ''

  constructor(data?: IEmployeeRelativeRelation) {
    Object.assign(this, data)
  }
}

export const employeeRelativeRelationNone = new EmployeeRelativeRelation({
  id: 0,
  name: 'NONE',
})
