import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col error-messages"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.messages.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (msg, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["alert alert-dismissible fade show", msg.alertClass]),
            role: "alert",
            key: i
          }, [
            _createElementVNode("span", {
              innerHTML: msg.message
            }, null, 8, _hoisted_2),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "aria-label": "Close",
              onClick: ($event: any) => (_ctx.removeErrorMessage(i))
            }, null, 8, _hoisted_3)
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}