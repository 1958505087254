import { AccountInfo } from '@azure/msal-browser'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from '..'
import { User } from '@/types'
import { UserService } from '@/services'

export interface IAccessToken {
  token: string
  tokenType: string
}

export interface ISessionState {
  account: AccountInfo | null
  accessToken: IAccessToken | null
  profilePhoto: string | null
  user: User | null
}

@Module({ dynamic: true, store, name: 'Session' })
class Session extends VuexModule implements ISessionState {
  get account(): AccountInfo | null {
    return this.mAccount
  }

  get profilePhoto(): string | null {
    return this.mProfilePhoto
  }

  get accessToken(): IAccessToken | null {
    return this.mAccessToken
  }

  get user(): User | null {
    return this.mUser
  }

  get updatingUser(): boolean {
    return this.mUserLoading
  }

  get isSignedIn(): boolean {
    return this.account !== null
  }

  get isSigningIn(): boolean {
    return this.mSigningIn
  }

  private mAccessToken: IAccessToken | null = null
  private mAccount: AccountInfo | null = null
  private mProfilePhoto: string | null = null

  private mUserLoading = false
  private mUser: User | null = null

  private mSigningIn = false

  @Mutation
  public setAccount(account: AccountInfo | null) {
    this.mAccount = account
  }

  @Mutation
  public clear() {
    this.mAccount = null
    this.mAccessToken = null
    this.mProfilePhoto = null
    this.mUser = null
  }

  @Mutation
  public setAccessToken(accessToken: IAccessToken | null) {
    this.mAccessToken = accessToken
  }

  @Action
  public async updateProfilePhoto() {
    try {
      const imgB64: string = await UserService.getUserAvatar()
      this.setProfilePhoto(imgB64)
    } catch (err) {
      window.console.error('Unable to update profile avatar:', err)
    }
  }

  @Mutation
  private setProfilePhoto(b64img: string) {
    this.mProfilePhoto = b64img
  }

  @Action
  public async updateUserInfo() {
    this.setUserInfoLoading(true)
    try {
      const user = await UserService.getUser()
      this.setUserInfo(user)
    } catch (err) {
      window.console.error('Error getting status from server:', err)
    } finally {
      this.setUserInfoLoading(false)
    }
  }

  @Mutation
  private setUserInfo(user: User) {
    this.mUser = user
  }

  @Mutation
  private setUserInfoLoading(loading: boolean) {
    this.mUserLoading = loading
  }

  @Mutation
  public setSigningIn(state: boolean) {
    this.mSigningIn = state
  }

  @Action
  public async signIn() {
    return await UserService.signIn()
  }

  @Action
  public async signOut() {
    return await UserService.signOut()
  }
}

export const SessionModule = getModule(Session)
