export class FmtUtils {
  /**
   * Format a Date as both date and time
   * @param dt Date to format
   * @returns formatted date
   */
  public static fmtDateTime(dt: Date | null | undefined): string {
    if (!dt) {
      return ''
    }

    return `${FmtUtils.fmtDate(dt)} kl. ${FmtUtils.fmtTime(dt)}`
  }

  /**
   * Format a Date as date
   * @param dt Date to format
   * @returns formatted date
   */
  public static fmtDate(dt: Date | null | undefined): string {
    if (!dt) {
      return ''
    }

    return Intl.DateTimeFormat('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(dt)
  }

  /**
   * Format a Date as time
   * @param dt Date to format
   * @returns formatted date
   */
  public static fmtTime(dt: Date | null | undefined): string {
    if (!dt) {
      return ''
    }

    return Intl.DateTimeFormat('sv-SE', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(dt)
  }

  //
  //  Phone numbers
  //
  static phoneNumberPrefix3 = [
    '010',
    '011',
    '013',
    '016',
    '018',
    '019',
    '021',
    '024',
    '026',
    '031',
    '033',
    '035',
    '036',
    '040',
    '042',
    '044',
    '046',
    '054',
    '060',
    '063',
    '090',
  ]

  /**
   * Format a phone number
   *
   * @param value Phone number to be formatted
   * @returns formatted phone number
   */
  public static fmtPhoneNumber(value: string | null | undefined): string {
    if (!value) {
      return ''
    } else if (value.length > 10) {
      value = value.substring(0, 10)
    }

    if (value.startsWith('08')) {
      switch (value.length) {
        case 10:
          return value.replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{2})?/g, '$1-$2 $3 $4')
        case 9:
          return value.replace(/^(\d{2})?(\d{3})?(\d{2})?(\d{2})?/g, '$1-$2 $3 $4')
        case 8:
          return value.replace(/^(\d{2})?(\d{2})?(\d{2})?(\d{2})?/g, '$1-$2 $3 $4')
        case 7:
        case 6:
        case 5:
        case 4:
          return value.replace(/^(\d{2})?(\d+)?/g, '$1-$2')
        default:
          return value
      }
    }

    for (const prefix of FmtUtils.phoneNumberPrefix3) {
      if (value.startsWith(prefix)) {
        switch (value.length) {
          case 10:
            return value.replace(/^(\d{3})?(\d{3})?(\d{2})?(\d{2})?/g, '$1-$2 $3 $4')
          case 9:
            return value.replace(/^(\d{3})?(\d{2})?(\d{2})?(\d{2})?/g, '$1-$2 $3 $4')
          case 8:
            return value.replace(/^(\d{3})?(\d{3})?(\d{2})?/g, '$1-$2 $3')
          case 7:
          case 6:
          case 5:
          case 4:
            return value.replace(/^(\d{3})?(\d+)?/g, '$1-$2')
          default:
            return value
        }
      }
    }

    switch (value.length) {
      case 10:
        return value.replace(/^(\d{4})?(\d{2})?(\d{2})?(\d{2})?/g, '$1-$2 $3 $4')
      case 9:
        return value.replace(/^(\d{4})?(\d{3})?(\d{2})?/g, '$1-$2 $3')
      case 8:
      case 7:
      case 6:
      case 5:
        return value.replace(/^(\d{4})?(\d+)?/g, '$1-$2')
      default:
        return value
    }
  }

  /**
   * Format a postal code
   * @param value Postal code to format
   * @returns formatted postal code
   */
  public static fmtPostalCode(value: string | null | undefined): string {
    if (!value) {
      return ''
    } else if (value.length != 5) {
      return value
    }

    return value.replace(/^(\d{3})?(\d+)?/g, '$1 $2')
  }

  /**
   * Format a personal identity number (personnummer)
   * @param value Personal identity number (personnummer) to format
   * @returns formatted personal identity number (personnummer)
   */
  public static fmtPersonalIdentityNumber(value: string | null | undefined): string {
    if (!value) {
      return ''
    } else if (value.length > 10) {
      value = value.substring(0, 10)
    }

    if (value.length > 6) {
      value = value.replace(/^(\d{6})?(\d{4})?/g, '$1-$2')
    }

    return value
  }
}
