import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core'
import {
  faPhone,
  faSpinner,
  faSyncAlt,
  faCheck,
  faInfoCircle,
  faArrowRight,
  faPenToSquare,
  faList,
  faSearch,
  faPrint,
} from '@fortawesome/free-solid-svg-icons'
import { App } from 'vue'

export function fontAwesome(app: App): void {
  app.component('fa-icon', FontAwesomeIcon)
}

library.add(faPhone as IconDefinition)
library.add(faSpinner as IconDefinition)
library.add(faSyncAlt as IconDefinition)
library.add(faCheck as IconDefinition)
library.add(faInfoCircle as IconDefinition)
library.add(faArrowRight as IconDefinition)
library.add(faPenToSquare as IconDefinition)
library.add(faList as IconDefinition)
library.add(faSearch as IconDefinition)
library.add(faPrint as IconDefinition)
