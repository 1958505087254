
import axios, { AxiosError } from 'axios'
import { Vue } from 'vue-class-component'
import { Options } from 'vue-class-component'

export enum MessageType {
  ERROR,
  INFO,
}

export class Message {
  message = ''
  type = MessageType.ERROR

  constructor(message: string, type: MessageType = MessageType.ERROR) {
    this.message = message
    this.type = type
  }

  get alertClass(): string {
    switch (this.type) {
      case MessageType.ERROR:
        return 'alert-danger'

      case MessageType.INFO:
      default:
        return 'alert-success'
    }
  }
}

@Options({
  name: 'MessagesComponent',
})
export default class MessagesComponent extends Vue {
  messages: Message[] = []

  public pushInfo(msg: string): void {
    this.messages.push(new Message(msg, MessageType.INFO))
  }

  public pushErr(err: AxiosError | unknown): void {
    if (typeof err == 'string') {
      this.messages.push(new Message(err, MessageType.ERROR))
    } else if (axios.isAxiosError(err)) {
      this.handleAxiosError(err as AxiosError)
    } else if (err instanceof Error && err.message) {
      this.messages.push(new Message(err.message, MessageType.ERROR))
    } else {
      this.messages.push(new Message(`[Unknown error type] ${err}`, MessageType.ERROR))
    }
  }

  private handleAxiosError(err: AxiosError): void {
    const msgs = []

    if (err.response) {
      if (err.response.data.error) {
        if (typeof err.response.data.error == 'string') {
          msgs.push(err.response.data.error)
        } else if (typeof err.response.data.error == 'object') {
          for (const errMsg of err.response.data.error) {
            msgs.push(errMsg)
          }
        }
      }

      if (err.response.data.errors) {
        for (const prop in err.response.data.errors) {
          msgs.push(`${prop}: ${err.response.data.errors[prop]}`)
        }
      }

      if (err.response.data.validationErrors) {
        for (const prop in err.response.data.validationErrors) {
          for (const msg of err.response.data.validationErrors[prop]) {
            msgs.push(`Property ${prop}: ${msg}`)
          }
        }
      }

      // Fallback: Print general error message
      if (msgs.length == 0) {
        msgs.push(`[${err.response.status}] ${err.response.statusText}`)
      }
    } else if (err.request) {
      msgs.push(err.request)
    } else if (err.message) {
      msgs.push(err.message)
    }

    let s = msgs.join('<br>')

    const corrId = err.response?.headers['x-correlation-id']
    if (corrId) {
      s += `<br><br>(CorrelationID: ${corrId})`
    }

    this.messages.push(new Message(s, MessageType.ERROR))
  }

  public clear(): void {
    this.messages.length = 0
  }

  removeErrorMessage(idx: number): void {
    this.messages.splice(idx, 1)
  }
}
